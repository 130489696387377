import React, { FunctionComponent } from 'react'
import * as S from './styled'

type Props = {
  number: number
}

const NumberBubble: FunctionComponent<Props> = ({ number }) => {
  return <S.Circle>{number}</S.Circle>
}

export default NumberBubble
