import React, { FunctionComponent } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { Spacer } from '../../theme/base'
import { Body1, Headline1, Headline3 } from '../../theme/typography'
import NumberBubble from '../__general/NumberBubble'
import * as S from './styled'

const processSteps = [
  {
    id: 1,
    title: 'Proof Of Concept',
    description:
      'Our simple entry model. We leave the kickoff meeting with a plan to validate a selected hypothesis, and within a week we deliver a prototype to test your hypothesis.',
  },
  {
    id: 2,
    title: 'MVP',
    description:
      'Building on a validated hypothesis, we make your AI solution production-ready and assist you in going live.',
  },
  {
    id: 3,
    title: 'Scale up',
    description:
      'We continue from your MVP and refine functionality and results. Based on your needs, the solution is developed to be more user-friendly, cover a broader functional range, and deliver more accurate results.',
  },
]

const AIProcess: FunctionComponent = () => {
  return (
    <Container>
      <S.TextHeader>
        <Headline1 style={{ fontSize: '5.4rem' }}>How we do it.</Headline1>
        <Spacer h16 />
        <Body1>
          Over the years we’ve learned what works and what doesn’t to achieve a successful project.
          Whether it’s AI or something else. This is how we suggest you move on with us in a three
          step process. If it sounds good, let’s book an exploration meeting.
        </Body1>
      </S.TextHeader>
      <Spacer h40 />
      <Row style={{ rowGap: 40 }}>
        {processSteps.map(({ id, title, description }) => (
          <Col xxl={4} xl={6} key={id}>
            <S.Card>
              <Row style={{ margin: 0 }}>
                <NumberBubble number={id} />
              </Row>
              <Spacer h16 />
              <Headline3>{title}</Headline3>
              <Spacer h08 />
              <S.CardDescription>{description}</S.CardDescription>
              <Spacer h24 />
            </S.Card>
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default AIProcess
