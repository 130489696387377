import styled from 'styled-components'
import { breakpoints } from '../../theme/mediaBreakpoints'
import { Body2, bodyFontBold, typographyBase } from '../../theme/typography'

export const Container = styled.div`
  min-height: 800px;
  width: 100%;
  padding-top: 80px;

  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
`

// for new design
export const Title = styled.h1`
  ${typographyBase}
  font-family: 'Marvin Visions Variable';
  font-size: 64px;

  ${breakpoints.tablet} {
    font-size: 5rem;
  }
`

export const Cta = styled.div`
  text-align: left;
  padding: 32px;
  border: 2px solid var(--accent);
  border-radius: 16px;
  color: var(--accent);
  background-color: var(--accent-lightest);

  max-width: 700px;
  margin: 0 auto;
`

export const Content = styled.div`
  text-align: center;
`

export const CtaHeadline = styled.h3`
  ${typographyBase}
  ${bodyFontBold}
  font-size: 2.6rem;
  letter-spacing: 0.1rem;
  color: var(--accent);
`
export const RedSpan = styled.span`
  color: var(--accent);
  ${bodyFontBold}
`

export const CtaText = styled(Body2)`
  font-size: 1.8rem;
  line-height: 1.5;
`
