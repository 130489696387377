import styled from 'styled-components'
import { breakpoints } from '../../theme/mediaBreakpoints'
import { Body2 } from '../../theme/typography'

export const Container = styled.div`
  max-width: 2560px;
  margin: 0 auto;

  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
`

export const TextHeader = styled.div`
  max-width: 700px;
`

export const Card = styled.div`
  background-color: var(--background-variant);
  border-radius: 16px;
  padding: 32px;
  display: flex;
  height: 100%;
  flex-direction: column;
`
export const CardDescription = styled(Body2)`
  font-size: 1.8rem;
  line-height: 1.5;
`

export const PackageContainer = styled.div`
  display: flex;
  flex-direction: 'row';

  ${breakpoints.mobile} {
    flex-direction: column;
    gap: 1.6rem;
  }
`
