import { navigate } from 'gatsby'
import React, { FunctionComponent } from 'react'
import { Zoom } from 'react-awesome-reveal'
import { Col, Container, Row } from 'react-grid-system'
import Strategy from '../../assets/svg/Strategy'
import { Spacer } from '../../theme/base'
import { Body1, Body3, Title3 } from '../../theme/typography'
import Button from '../Button'
import * as S from './styled'

type Props = {}

const AIHero: FunctionComponent<Props> = () => {
  const title = 'Exploration Meeting'

  return (
    <S.Container>
      <Spacer exact={120} />
      <Container>
        <Row>
          <Col>
            <S.Content>
              <Title3 style={{ fontSize: '6.4rem' }}>Explore Your AI journey WITH REDMIND</Title3>
              <Spacer h24 />
              <Body1 style={{ maxWidth: '900px', margin: '0 auto' }}>
                AI is starting to appear everywhere. A bit scary. Yet enticing. Reshaping things we
                knew. Creating opportunites. Becoming available. Groundbreaking. Also just another
                new technology appearing. Let’s book an exploration meeting and take it from there.
              </Body1>
              <Spacer exact={100} />
              <Zoom triggerOnce>
                <S.Cta>
                  <Strategy />
                  <Spacer h16 />
                  <S.CtaHeadline>{title}</S.CtaHeadline>
                  <Spacer h16 />
                  <S.CtaText>
                    We meet and go through your needs. You might have an idea ready but need help
                    with implementation. Have a need but lack a solution. Or just a sense that this
                    technology would fit well for your challenges. Meet us in a workshop where we
                    arrive at a way forward.
                  </S.CtaText>
                  <Spacer h16 />
                  <Body3>
                    Cost: <S.RedSpan>Free</S.RedSpan>
                  </Body3>
                  <Spacer h04 />
                  <Body3>
                    Time: <S.RedSpan>2 hours</S.RedSpan>
                  </Body3>
                  <Spacer h24 />
                  <Button
                    size="small"
                    title="Let’s go"
                    style={{ width: '100%' }}
                    onClick={() =>
                      navigate('/contact', {
                        state: { message: `I'm interested in 🤖 ${title}\n\n` },
                      })
                    }
                  />
                </S.Cta>
              </Zoom>
            </S.Content>
          </Col>
        </Row>
      </Container>
    </S.Container>
  )
}

export default AIHero
