import { graphql, navigate, useStaticQuery } from 'gatsby'
import React, { FunctionComponent } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { Spacer } from '../../theme/base'
import Button from '../Button'
import * as S from './styled'

const query = graphql`
  query {
    allContentfulEmployee(filter: { name: { in: "Fredrik Agegård" } }) {
      edges {
        node {
          linkedin
          name
          title
          fauxTitle
          picture {
            fixed(height: 1000) {
              srcWebp
              src
            }
          }
        }
      }
    }
  }
`

const AIContact: FunctionComponent = () => {
  const { allContentfulEmployee } = useStaticQuery(query)

  const [employee] = allContentfulEmployee.edges.map(edge => edge.node)

  return (
    <S.Container>
      <Container>
        <Row align="center" style={{ rowGap: 24 }}>
          <Col lg={2} xs={4} offset={{ lg: 1 }}>
            <S.Image src={employee.picture.fixed.srcWebp ?? employee.picture.fixed.src} />
          </Col>
          <Col xxl={6} xl={8} md={12}>
            <div>
              <S.Headline>We. AI. You?</S.Headline>
              <Spacer h16 />
              <S.QuotedText>
                “We’ve finished a bunch of customer projects focused around AI now and are in the
                middle of a couple more. We love the journey from problem to solution. Now with AI
                as another tool in the toolbox.”
              </S.QuotedText>
              <Spacer h12 />
              <S.Author>Fredrik Agegård, CEO at Redmind</S.Author>
              <Spacer h16 />
              <Button
                title="Contact us"
                size="small"
                onClick={() =>
                  navigate('/contact', {
                    state: { message: `I'm interested in AI 🤖!\n\n` },
                  })
                }
              />
            </div>
          </Col>
        </Row>
      </Container>
    </S.Container>
  )
}

export default AIContact
