import * as React from 'react'
import Reveal from 'react-awesome-reveal'
import { Helmet } from 'react-helmet'
import AIContact from '../components/AIContact'
import AIHero from '../components/AIHero'
import AIPackages from '../components/AIPackages'
import AIProcess from '../components/AIProcess'
import AiWork from '../components/AiWork'
import Footer from '../components/Footer'
import Header from '../components/Header'
import '../css/index.css'
import animations from '../theme/animations'
import { Spacer } from '../theme/base'

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>AI</title>
        <meta
          name="description"
          content="We blend expertise and innovation to deliver top-tier AI solutions. Our focus is on elevating your business operations through tailor-made artificial intelligence technologies"
        />
        <html lang="en" />
      </Helmet>
      <main style={{ position: 'relative' }}>
        <Header />
        <AIHero />
        <Spacer exact={200} />
        <Reveal triggerOnce fraction={0.3} keyframes={animations.slideUp} duration={500}>
          <AIPackages />
        </Reveal>
        <Spacer size="large" />
        <Spacer size="large" />
        <Reveal triggerOnce fraction={0.3} keyframes={animations.slideUp} duration={500}>
          <AIProcess />
        </Reveal>
        <Spacer size="large" />
        <Spacer size="large" />
        <AiWork />
        <Spacer size="large" />
        <AIContact />
        <Footer />
      </main>
    </>
  )
}

export default IndexPage
