import { graphql, navigate, useStaticQuery } from 'gatsby'
import React, { FunctionComponent } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { Spacer } from '../../theme/base'
import { Body1, Headline1, Headline3 } from '../../theme/typography'
import Button from '../Button'

import * as S from './styled'

const query = graphql`
  query {
    allContentfulAiPackages {
      edges {
        node {
          id
          title
          time
          cta
          description {
            id
            description
          }
          icon {
            file {
              url
              contentType
            }
          }
        }
      }
    }
  }
`

const AIHero: FunctionComponent = () => {
  const { allContentfulAiPackages } = useStaticQuery(query)

  return (
    <Container>
      <S.TextHeader>
        <Headline1 style={{ fontSize: '5.4rem' }}>Accelerate. Fast.</Headline1>
        <Spacer h16 />
        <Body1>
          Get a head start in your AI journey. Try one of our packages to get quick results. And get
          a feeling of how it is to work with us.
        </Body1>
      </S.TextHeader>
      <Spacer h40 />
      <Row style={{ rowGap: 40 }}>
        {allContentfulAiPackages.edges.map(({ node }) => (
          <Col xxl={4} xl={6} key={node.id}>
            <S.Card>
              <div style={{ flex: 1 }}>
                <img src={node.icon.file.url} alt={node.icon.title} />
                <Spacer h16 />
                <Headline3>{node.title}</Headline3>
                <Spacer h08 />
                <S.CardDescription>{node.description.description}</S.CardDescription>
                <Spacer h24 />
                <S.CardDescription>
                  Time: <span style={{ fontFamily: 'Aeonik-Bold' }}>{node.time}</span>
                </S.CardDescription>
              </div>
              <Spacer h24 />
              <Button
                variant="secondary"
                title={node.cta}
                size="small"
                style={{ width: '100%' }}
                onClick={() =>
                  navigate('/contact', {
                    state: { message: `I'm interested in 🤖 ${node.title}\n\n` },
                  })
                }
              />
            </S.Card>
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default AIHero
