import styled from 'styled-components'
import { Body2 } from '../../theme/typography'

export const TextHeader = styled.div`
  max-width: 750px;
`

export const Card = styled.div`
  background-color: var(--background-variant);
  border-radius: 16px;
  height: 100%;
  padding: 32px;
`

export const CardDescription = styled(Body2)`
  font-size: 1.8rem;
  line-height: 1.5;
`

export const ProcessContainer = styled.div`
  display: flex;
  flex-direction: 'row';
`
