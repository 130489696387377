import { graphql, useStaticQuery } from 'gatsby'
import React, { FC } from 'react'
import { Container } from 'react-grid-system'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { Spacer } from '../../theme/base'
import { Headline1 } from '../../theme/typography'
import { Client } from '../../types'
import ConceptCard from '../ConceptCard/ConceptCard'

type Props = Record<string, never>

const query = graphql`
  query {
    allContentfulClient(filter: { tags: { eq: "AI" } }) {
      edges {
        node {
          previewImage {
            fluid(quality: 100) {
              src
              srcWebp
            }
          }
          previewThumbnail: previewImage {
            fluid(quality: 5) {
              srcWebp
            }
          }
          name
          slogan
          slug
          tags
          categories
          contentful_id
          aiParagraph {
            aiParagraph
          }
        }
      }
    }
  }
`

const AiWork: FC<Props> = () => {
  const { allContentfulClient } = useStaticQuery(query)

  const clients: Client[] = allContentfulClient.edges.map(({ node }) => node)

  const clientCards = clients.map(client => (
    <ConceptCard
      key={client.contentful_id}
      concept={client}
      hasConcept={false}
      activePills={['ai']}
      aiParagraphVisible
    />
  ))

  return (
    <Container>
      <Headline1 style={{ fontSize: '5.4rem' }}>Selected AI work.</Headline1>
      <Spacer h40 />
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2 }}>
        <Masonry gutter="80px">{clientCards}</Masonry>
      </ResponsiveMasonry>
    </Container>
  )
}

export default AiWork
