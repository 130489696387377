import styled from 'styled-components'

export const Circle = styled.div`
  width: 40px;
  height: 40px;
  background-color: var(--foreground);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--background);
  font-size: 16px;
  font-family: 'Aeonik-Bold';
  font-size: 2rem;
`
