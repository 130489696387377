import styled from 'styled-components'
import { breakpoints } from '../../theme/mediaBreakpoints'
import { Body1, Body3, Headline1 } from '../../theme/typography'

export const Container = styled.div`
  background: #1f2125;
  padding: 80px 0;

  ${breakpoints.mobile} {
    padding: 40px 0;
  }
`

export const QuotedText = styled(Body1)`
  font-style: italic;
  color: var(--background);
`
export const Headline = styled(Headline1)`
  color: var(--background);
`

export const Author = styled(Body3)`
  color: var(--background);
`

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 80px;
`

export const Image = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  border-radius: 36% 64% 67% 33% / 47% 25% 75% 53%;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 0px;
  padding-top: 100%;
`
